import React, { useState, useEffect, useRef } from "react";
import { api_base, loadUsers } from "../services/consts";
import OurHeader from "./OurHeader";
import GoogleMapReact from "google-map-react";

function Stats(props) {
  const [recentSearches, setRecentSearches] = useState([]);
  const [mostSearchedParts, setMostSearchedParts] = useState([]);
  const [maxSearches, setMaxSearches] = useState(0);
  const mapContainer = useRef({});

  const loadRecentSearches = () => {
    console.log("loading pricelists");

    fetch(api_base + "/searchapp/searches/getAll", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res) {
          return res.json();
        }
        return null;
      })
      .then(data => {
        console.log(data);
        setRecentSearches(data);
      });
  };

  const loadMostSearchedParts = () => {
    fetch(api_base + "/searchapp/searches/group-by-user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res) {
          return res.json();
        }
        return null;
      })
      .then(data => {
        if (data.length > 0) {
          console.log("most searched", data);
          setMaxSearches(data[0].data);
          console.log(data[0].total);
          setMostSearchedParts(data);
        }
      });
  };

  const defaultProps = {
    center: {
      lat: 37.0,
      lng: -84.63
    },
    zoom: 6
  };

  const AnyReactComponent = ({ text }) => (
    <div
      style={{
        width: "10px",
        height: "10px",
        backgroundColor: "red",
        borderRadius: "5px"
      }}
    ></div>
  );

  useEffect(() => {
    loadMostSearchedParts();
    loadRecentSearches();
  }, []);

  return (
    <div>
      <OurHeader history={props.history}></OurHeader>

      <div class="container" style={{ marginTop: "40px" }}>
        <div class="row">
          <div class="col-md-12 text-center">
            <h2>Map of Recent Searches</h2>
            <div style={{ height: "400px", width: "100%" }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyA7lIOssygTlsjWhUe2z329azbSTqUXWXs"
                }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
              >
                {recentSearches.map(u => {
                  if (u.coords) {
                    if (u.coords.latitude) {
                      return (
                        <AnyReactComponent
                          lat={u.coords.latitude}
                          lng={u.coords.longitude}
                          text="My Marker"
                        ></AnyReactComponent>
                      );
                    }
                  }
                })}
              </GoogleMapReact>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card mt-3">
              <div class="card-body">
                <h2>Most Searched Parts</h2>
                {mostSearchedParts.map(u => {
                  const width = (u.total / maxSearches) * 100;
                  return (
                    <div>
                      <h5>
                        <strong>OEM: </strong>
                        {u.oem} - <strong>Searches: </strong>
                        {u.total}
                      </h5>
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{ width: width + "%" }}
                          value={((u.total / maxSearches) * 100).toString()}
                          aria-valuenow={(
                            (u.total / maxSearches) *
                            100
                          ).toString()}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card mt-3">
              <div class="card-body">
                <h2>Recent Searches</h2>
                {recentSearches.map(u => {
                  return (
                    <div>
                      <p>
                        <strong>Username: </strong> {u.username} -{" "}
                        <strong>OEM: {u.oem}</strong>
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Stats;
