import React, { useState, useEffect, useContext } from "react";
import { login } from "../services/consts";
import OurHeader from "../components/OurHeader";

function Login(props) {
  const defaultSettings = {
    percup: 10,
    markup: 1
  };

  const [settings, setSettings] = useState(defaultSettings);

  const bootup = async () => {
    const userinfo = await login(settings.username, settings.password, user => {
      if (user !== null) {
        console.log(user);
        localStorage["userinfo"] = JSON.stringify(user);
        window.location.reload();
      } else {
        alert("User Not Found");
        return false;
      }
    });
  };

  const setUsername = evt => {
    settings.username = evt.target.value;

    setSettings(settings);
  };

  const setPassword = evt => {
    console.log("SETTING PASSWORD");
    settings.password = evt.target.value;
    setSettings(settings);
  };

  const saveSettings = () => {
    localStorage["userinfo"] = JSON.stringify(settings);
    bootup();
  };

  const loadSettings = () => {
    if (localStorage["userinfo"]) {
      setSettings(JSON.parse(localStorage["userinfo"]));
    }
  };

  useEffect(() => {
    loadSettings();
  }, []);

  const { test } = props;
  console.log("userContext", props.history);

  return (
    <div>
      <OurHeader history={props.history}></OurHeader>
      <div class="container">
        <form style={{ marginTop: "40px" }}>
          <div class="form-group">
            <label for="exampleInputEmail1">Username</label>
            <input
              type="text"
              onInput={setUsername}
              value={settings.username}
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Password</label>
            <input
              type="text"
              value={settings.password}
              onInput={setPassword}
              class="form-control"
            />
          </div>
          <button
            type="button"
            onClick={() => saveSettings()}
            class="btn btn-primary pull-right"
          >
            Sign In
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
