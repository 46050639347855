import React, { useState, useEffect, useRef } from "react";
import { api_base, loadUsers } from "../services/consts";
import OurHeader from "./OurHeader";

function ManagePricelists(props) {
  const [pricelists, setPricelists] = useState([]);
  const [searchingPricelistId, setSearchingPricelistId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setAddShowModal] = useState(false);
  const [searches, setSearches] = useState([]);
  const [searchnumber, setSearchnumber] = useState("");
  const [pricelistName, setPricelistName] = useState("");
  const [searchModal, setSearchModal] = useState(false);

  const fileInput = useRef(null);

  const getParsecsvdata = function(data) {
    let parsedata = [];

    let newLinebrk = data.split("\n");
    for (let i = 0; i < newLinebrk.length; i++) {
      parsedata.push(newLinebrk[i].split(","));
    }

    console.table(parsedata);

    const newPriceSheet = {
      name: pricelistName,
      oems: [],
      date: new Date().toLocaleDateString()
    };

    for (const row of parsedata) {
      let replacement;
      if (row[1]) {
        replacement = parseInt(row[1].replace("$", ""));
      } else {
        replacement = 0;
      }

      newPriceSheet.oems.push({
        number: row[0],
        price: replacement
      });
    }

    console.log(newPriceSheet);

    fetch(api_base + "/searchapp/pricelists/put", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({ pricelist: newPriceSheet })
    })
      .then(res => {
        if (res) {
          return res;
        }
        return null;
      })
      .then(data => {
        console.log(data);
        //fireToast("User Added");
        //props.history.push("/manage-users");
        loadPricelists();
        setAddShowModal(false);
      });
  };

  const submit = evt => {
    evt.preventDefault();
    const myFile = fileInput.current.files[0];
    console.log(myFile);
    const reader = new FileReader(myFile);

    reader.addEventListener("load", function(e) {
      let csvdata = e.target.result;
      getParsecsvdata(csvdata); // calling function for parse csv data
    });

    reader.readAsBinaryString(myFile);
  };

  const loadPricelists = () => {
    console.log("loading pricelists");

    fetch(api_base + "/searchapp/pricelists/getAll", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res) {
          return res.json();
        }
        return null;
      })
      .then(data => {
        console.log(data);
        setPricelists(data);
      });
  };

  const removePricelist = pricelist_id => {
    fetch(api_base + "/searchapp/pricelists/remove", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ pricelist_id: pricelist_id })
    })
      .then(res => {
        if (res) {
          return res;
        }
        return null;
      })
      .then(data => {
        console.log(data);
        loadPricelists(data);
      });
  };

  const searchPricelist = pricelist_id => {
    setSearchingPricelistId(pricelist_id);
    setShowModal(true);
  };

  const searchFeaturedPricelist = val => {
    const oem = val.target.value;
    setSearchnumber(oem);

    const payload = {
      pricelist_id: searchingPricelistId,
      oem: searchnumber
    };

    fetch(api_base + "/searchapp/pricelists/search", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(payload)
    })
      .then(res => {
        if (res) {
          return res.json();
        }
        return null;
      })
      .then(data => {
        console.log(data);
        setSearches(data);
      });
  };

  const doAddPricelist = () => {
    setAddShowModal(true);
  };

  useEffect(() => {
    loadPricelists();
  }, []);

  return (
    <div>
      <OurHeader history={props.history}></OurHeader>
      <div class="container">
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <button
            class="btn btn-success pull-right"
            onClick={() => doAddPricelist()}
          >
            ADD PRICELIST
          </button>
          <a
            class="btn btn-success pull-right ml-3"
            href="/#/assign-pricelists"
          >
            ASSIGN PRICELISTS
          </a>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Name</th>
              <th scope="col">Database ID</th>
            </tr>
          </thead>
          <tbody>
            {pricelists.length &&
              pricelists.map(u => {
                return (
                  <tr key={u._id}>
                    <th scope="row">{new Date(u.date).toLocaleDateString()}</th>
                    <td>{u.name}</td>
                    <td>{u._id}</td>
                    <td>{u.oems && u.oems.length}</td>

                    <td>
                      <button
                        class="btn btn-danger"
                        onClick={() => removePricelist(u._id)}
                      >
                        DELETE
                      </button>
                    </td>
                    <td>
                      <button
                        class="btn btn-success"
                        onClick={() => searchPricelist(u._id)}
                      >
                        SEARCH
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        {searchModal && (
          <div class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">View Searches</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    onClick={() => setSearchModal(false)}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Pricelist Name</label>
                    <input
                      type="text"
                      value={pricelistName}
                      onInput={evt => setPricelistName(evt.target.value)}
                      class="form-control"
                    />
                  </div>
                  <form>
                    <input
                      ref={fileInput}
                      type="file"
                      accept=".csv"
                      id="dealCsv"
                    />
                    <button
                      onClick={submit}
                      expand="block"
                      color="btn btn-primary"
                    >
                      Upload
                    </button>
                  </form>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => setAddShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {showAddModal && (
          <div class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Add Pricelist</h5>
                  <button
                    type="button"
                    class="close"
                    onClick={() => setAddShowModal(false)}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Pricelist Name</label>
                    <input
                      type="text"
                      value={pricelistName}
                      onInput={evt => setPricelistName(evt.target.value)}
                      class="form-control"
                    />
                  </div>
                  <form>
                    <input ref={fileInput} type="file" id="dealCsv" />
                    <button
                      onClick={submit}
                      expand="block"
                      color="btn btn-primary"
                    >
                      Upload
                    </button>
                  </form>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => setAddShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showModal && (
          <div class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Searching Pricelist</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setShowModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <form>
                    <div class="form-group">
                      <label for="exampleInputEmail1">Search</label>
                      <input
                        type="text"
                        value={searchnumber}
                        onInput={searchFeaturedPricelist}
                        class="form-control"
                      />
                    </div>
                    <ul>
                      {searches.map(u => {
                        return (
                          <li>
                            <strong>OEM: </strong>
                            {u.number} - <strong>Price</strong> ${u.price}
                          </li>
                        );
                      })}
                    </ul>
                  </form>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default ManagePricelists;
